class Topic {
    constructor(id, slug, slug_path, type, parent_id, updated_at, created_at) {
        this.id = id;
        this.slug = slug;
        this.slug_path = slug_path;
        this.type = type;
        this.parent_id = parent_id;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}

export default Topic;