import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axios";
import {Link, useParams} from "react-router-dom";
import Topic from "./TopicClass"



function TopicNav({topic = new Topic, editable = Boolean}) {

    return (
        <div className="d-flex w-100">
            {!editable &&
                <Link to={'view'} className="btn btn-outline-warning rounded-0" >New</Link>
            }
            {topic && !editable &&
                <Link to={`view?id=${topic.id}`} className="btn btn-outline-warning rounded-0" >View</Link>
            }
            {topic && editable &&
                <button className="btn btn-outline-warning rounded-0">Save</button>
            }
        </div>
    );
}

export default TopicNav;
