import {
    createBrowserRouter, Outlet,
    RouterProvider,
} from "react-router-dom";
import Root from './components/Root';
import ChatSettings from "./components/chat_settings/ChatSettings";
import GreetingsSettings from "./components/chat_settings/general/GreetingsSettings";
import CommandsAccesses from "./components/chat_settings/general/CommandsAccesses";
import AntiSpam from "./components/chat_settings/general/AntiSpam";
import MessageLinks from "./components/chat_settings/general/MessageLinks";
import DickFap from "./components/chat_settings/entertainment/DickFap";
import LowInteraction from "./components/chat_settings/entertainment/LowInteraction";
import RpMrp from "./components/chat_settings/entertainment/RpMrp";
import DefaultRestrictions from "./components/chat_settings/general/DefaultRestrictions";
import Rules from "./components/chat_settings/general/Rules";
import UserProfile from "./components/user/UserProfile";
import MRP from "./components/user/customization/Mrp";
import MrpForm from "./components/user/customization/MrpForm";
import Shop from "./components/shop/Shop";
import SpinWheel from "./components/open_case/halloween/SpinWheel";
import Halloween from "./components/open_case/halloween/Halloween";
import Trade from "./components/trade/Trade";
import Inventory from "./components/user/customization/Inventory";
import OpenCase from "./components/open_case/OpenCase";
import RPe from "./components/user/customization/rpe/RPe";
import RPEForm from "./components/user/customization/rpe/RPEForm";
import Top from "./components/chat_settings/general/Top";
import TopicList from "./components/admin/topic/TopicList";
import TopicView from "./components/admin/topic/TopicView";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "chatSettings/:chatId",
                children: [
                    {
                        path: "",
                        element: <ChatSettings />,
                    },
                    {
                        path: "greetings",
                        element: <GreetingsSettings />,
                    },
                    {
                        path: "commandsAccesses",
                        element: <CommandsAccesses />
                    },
                    {
                        path: "antiSpam",
                        element: <AntiSpam />
                    },
                    {
                        path: "messageLinks",
                        element: <MessageLinks />
                    },
                    {
                        path: "dickFap",
                        element: <DickFap />
                    },
                    {
                        path: "lowInteraction",
                        element: <LowInteraction/>
                    },
                    {
                        path: "rpMrp",
                        element: <RpMrp/>
                    },
                    {
                        path: "defaultRestrictions",
                        element: <DefaultRestrictions/>
                    },
                    {
                        path:"rules",
                        element: <Rules/>
                    },
                    {
                        path:"tops",
                        element: <Top/>
                    }
                ]
            },
            {
                path: "/user",
                children: [
                    {
                        path: "",
                        element: <UserProfile/>,
                    },
                    {
                        path:"mrp",
                        children:[
                            {
                                path: "",
                                element: <MRP/>,
                            },
                            {
                                path: "edit",
                                element: <MrpForm/>
                            }
                        ]
                    },
                    {
                        path: "rpe",
                        children:[
                            {
                                path: "",
                                element: <RPe/>
                            },
                            {
                                path: "edit/:rpe_id",
                                element: <RPEForm/>
                            }
                        ]
                    },
                    {
                        path:"inv",
                        element: <Inventory/>
                    }
                ]
            },
            {
                path: "/shop",
                element: <Shop/>
            },
            {
                path: "openCase/:caseName",
                element: <OpenCase/>
            },
            {
                path: "/halloween",
                element:<Halloween/>,
                children: [
                    {
                        path: "spin",
                        element: <SpinWheel/>,
                    },
                ]
            },
            {
                path: "/trade",
                element: <Trade/>
            },

            {
                path: "/admin",
                children: [
                    {
                        path: "topic",
                        children: [
                            {
                                path: "",
                                element: <TopicList/>,
                            },
                            {
                                path: "view",
                                element: <TopicView/>
                            }
                        ]
                    }
                ]
            }
        ],
    },
]);

export default router;
