import React, { useEffect, useState } from "react";
import TopicTree from "./TopicTree";


const TopicEditor = () => {


    return (
        <div>
            <TopicTree/>
            <div>EditroPage</div>
        </div>
    );
};

export default TopicEditor;
