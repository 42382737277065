import Topic from "./TopicClass";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../../axios";
import TopicNav from "./TopicNav";

function TopicView() {
    const [searchParams] = useSearchParams();
    const [topic, setTopic] = useState(new Topic());
    const [suggestions, setSuggestions] = useState([]);
    const id = searchParams.get("id");

    useEffect(() => {
        const fetchTopic = async () => {
            try {
                const response = await axiosInstance.get(`/admin/topic?id=${id}`);
                const item = response.data;
                if (item) {
                    const newTopic = new Topic(
                        item.id,
                        item.slug,
                        item.slug_path,
                        item.type,
                        item.parent_id,
                        item.updated_at,
                        item.created_at
                    );
                    setTopic(newTopic);
                }
            } catch (error) {
                console.error("Failed to fetch topic:", error);
            }
        };
        if (id) {
            fetchTopic();
        }
    }, [id]);

    // Fetch available parent topics for the autocomplete functionality
    const fetchParentSuggestions = async (query) => {
        try {
            if (query.length > 2) {
                const response = await axiosInstance.get(`/admin/topic?slug=${query}`);
                setSuggestions(response.data);
            } else {
                setSuggestions([]);
            }
        } catch (error) {
            console.error("Failed to fetch parent suggestions:", error);
        }
    };

    const handleInputChange = (e, field) => {
        const { value } = e.target;
        setTopic((prevTopic) => ({
            ...prevTopic,
            [field]: value,
        }));
        if (field === 'parent_id') {
            fetchParentSuggestions(value); // Fetch suggestions for parent ID
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setTopic((prevTopic) => ({
            ...prevTopic,
            parent_id: suggestion.id,
        }));
        setSuggestions([]); // Hide suggestions after selection
    };

    return (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Topic Details</h5>
                    <TopicNav topic={topic} editable={true} />

                    {/* Slug */}
                    <div className="mb-3">
                        <label htmlFor="slug" className="form-label">Slug</label>
                        <input
                            type="text"
                            className="form-control"
                            id="slug"
                            value={topic.slug || ''}
                            onChange={(e) => handleInputChange(e, 'slug')}
                        />
                    </div>

                    {/* Slug Path */}
                    <div className="mb-3">
                        <label htmlFor="slug_path" className="form-label">Slug Path</label>
                        <input
                            type="text"
                            className="form-control"
                            id="slug_path"
                            value={topic.slug_path || ''}
                            readOnly
                        />
                    </div>

                    {/* Type */}
                    <div className="mb-3">
                        <label htmlFor="type" className="form-label">Type</label>
                        <input
                            type="text"
                            className="form-control"
                            id="type"
                            value={topic.type || ''}
                            onChange={(e) => handleInputChange(e, 'type')}
                        />
                    </div>

                    {/* Parent ID with Autocomplete */}
                    <div className="mb-3">
                        <label htmlFor="parent_id" className="form-label">Parent ID</label>
                        <input
                            type="text"
                            className="form-control"
                            id="parent_id"
                            value={topic.parent_id || ''}
                            onChange={(e) => handleInputChange(e, 'parent_id')}
                            list="parentSuggestions"
                        />
                        <datalist id="parentSuggestions">
                            {suggestions.map((suggestion) => (
                                <option
                                    key={suggestion.id}
                                    value={suggestion.id}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion.slug}
                                </option>
                            ))}
                        </datalist>
                    </div>
                    <div className="d-flex">
                        {/* Updated At */}
                        <div className="mb-3">
                            <label htmlFor="updated_at" className="form-label">Updated At</label>
                            <input
                                type="text"
                                className="form-control"
                                id="updated_at"
                                value={topic.updated_at || ''}
                                readOnly
                            />
                        </div>

                        {/* Created At */}
                        <div className="mb-3">
                            <label htmlFor="created_at" className="form-label">Created At</label>
                            <input
                                type="text"
                                className="form-control"
                                id="created_at"
                                value={topic.created_at || ''}
                                readOnly
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TopicView;
